import gql from 'graphql-tag';

export const UPDATE_USER_PROFILE = gql`
  mutation updateMyProfile($user: ProfileInput) {
    updateMyProfile(user: $user) {
      name
      role
      phone
      email
      image
      socialLinks {
        facebook
        linkedin
        twitter
      }
    }
  }
`;
export const UPDATE_NOMINEE_PROFILE = gql`
  mutation updateNomineeProfile($nomination: NomineeProfileInput) {
    updateNomineeProfile(nomination: $nomination) {
      name
      role
      email
      image
      editable
      uploadedFiles {
        profilePicture {
          link
          title
        }
        logo {
          title
          link
        }
        pitchDeck {
          title
          link
        }
      }
      socialLinks {
        facebook
        linkedin
        twitter
      }
    }
  }
`;

export const UPLOAD_FILE = gql`
  mutation uploadNomineePitchDeck($doc: Upload!) {
    uploadNomineePitchDeck(pitchDeck: $doc)
  }
`;

export const UPLOAD_LOGO = gql`
  mutation uploadNomineeLogo($doc: Upload!) {
    uploadNomineeLogo(logo: $doc)
  }
`;

export const UPLOAD_PROFILE_PICTURE = gql`
  mutation uploadNomineeProfilePicture($doc: Upload!) {
    uploadNomineeProfilePicture(profilePicture: $doc)
  }
`;

export const GET_FILES_FOR_NOMINEE = gql`
  query getNominee($id: ID!) {
    getNominationById(id: $id) {
      editable
      uploadedFiles {
        profilePicture {
          link
          title
        }
        logo {
          title
          link
        }
        pitchDeck {
          title
          link
        }
      }
    }
  }
`;
export const GET_NOMINATION_BY_ID = gql`
  query getNominationById($id: ID!) {
    getNominationById(id: $id) {
      name
      editable
      email
      emailSubscription
      individualName
      uploadedFiles {
        profilePicture {
          link
          title
        }
        logo {
          title
          link
        }
        pitchDeck {
          title
          link
        }
      }
      socialLinks {
        facebook
        linkedin
        twitter
      }
      categories {
        id
        name
      }
      finalizedCategories {
        id
        name
      }
    }
  }
`;

export const DELETE_MY_NOMINATION = gql`
  mutation deleteMyNomination {
    deleteMyNomination
  }
`;

export const DELETE_FILE_FROM_NOMINATION = gql`
  mutation deleteFileFromNomination($nominationFile: String!) {
    deleteFileFromNomination(nominationFile: $nominationFile)
  }
`;

export const SUBSCRIPTION_TRIGGER = gql`
  mutation switchSubscriptionTrigger($id: ID!, $emailSubscription: Boolean!) {
    switchSubscriptionTrigger(id: $id, emailSubscription: $emailSubscription)
  }
`;
