import React, { Component, Suspense } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import PrivateRoute from './containers/PrivateRouter';
import { pagesAccess } from './constants/roleAccess';
import styled from 'styled-components';
import propTypes from 'prop-types';
import lazyWithRetry from './lazyWithRetry';

const Page404 = lazyWithRetry(() => import('./containers/404'));
const Home = lazyWithRetry(() => import('./containers/Home'));
const Welcome = lazyWithRetry(() => import('./containers/Welcome'));
const Participant = lazyWithRetry(() => import('./containers/Participant'));
const UserProfile = lazyWithRetry(() => import('./containers/UserProfile/UserProfile'));
const Settings = lazyWithRetry(() => import('./containers/Settings'));
const ResetPassword = lazyWithRetry(() => import('./containers/auth/ResetPassword'));
const NominationPage = lazyWithRetry(() => import('./containers/NominationPage'));
const Reporting = lazyWithRetry(() => import('./containers/Reporting'));
const ReportingBy = lazyWithRetry(() => import('./containers/Reporting/ReportingBy'));
const SignUp = lazyWithRetry(() => import('./containers/auth/SignUp'));
const RegionsList = lazyWithRetry(() => import('./containers/Regions'));
const UsersList = lazyWithRetry(() => import('./containers/Users/indexFunc'));
const CompetitionsList = lazyWithRetry(() => import('./containers/Competition'));
const CategoriesList = lazyWithRetry(() => import('./containers/Categories'));
const Login = lazyWithRetry(() => import('./containers/auth/Login'));
const NominationsList = lazyWithRetry(() => import('./containers/Nomination'));
const PublicNomination = lazyWithRetry(() => import('./containers/PublicNomination'));
const PrivacyPolicy = lazyWithRetry(() => import('./containers/PrivacyPolicy'));
const EmailTemplates = lazyWithRetry(() => import('./containers/EmailTemplates'));
const Unsubscribe = lazyWithRetry(() => import('./containers/Unsubscribe'));
const AnswersResultsPage = lazyWithRetry(() => import('./containers/AnswersResultsPage'));
const VotingTemplates = lazyWithRetry(() => import('./containers/VotingTemplates'));
const PublicVoting = lazyWithRetry(() => import('./containers/PublicVoting'));
const QuestionnairePage = lazyWithRetry(() => import('./containers/QuestionnairePage'));
const RolePage = lazyWithRetry(() => import('./containers/RolePage'));
const PagesEditor = lazyWithRetry(() => import('./containers/Pages'));
const ImportNominations = lazyWithRetry(() => import('./containers/ImportNominations'));
const ApproveResetPassword = lazyWithRetry(() => import('./containers/auth/ApproveResetPassword'));

const Content = styled.div`
  height: calc(100vh - 60px);
  padding: 30px;
  overflow: auto;
  transition: all 0.3s ease;

  @media (max-width: 767px) {
    padding: 10px;
  }
`;

class ContentPages extends Component {
  shouldComponentUpdate(nextProps) {
    const { sideBarState } = this.props;

    return sideBarState === nextProps.sideBarState;
  }

  render() {
    return (
      <Content id="scroll-content">
        <Suspense fallback={null}>
          <Switch>
            <PrivateRoute
              exact
              path="/"
              component={withRouter(Home)}
              roles={pagesAccess.dashboard}
            />
            <PrivateRoute
              path="/welcome"
              component={withRouter(Welcome)}
              roles={pagesAccess.welcome}
            />
            <PrivateRoute
              path="/privacy-policy"
              component={withRouter(PrivacyPolicy)}
              roles={pagesAccess.dashboard}
            />
            <PrivateRoute
              path="/regions-list"
              component={withRouter(RegionsList)}
              roles={pagesAccess.region}
            />
            <PrivateRoute
              path="/users"
              component={withRouter(UsersList)}
              roles={pagesAccess.inviteUser}
            />
            <PrivateRoute
              path="/categories"
              component={withRouter(CategoriesList)}
              roles={pagesAccess.categories}
            />
            <PrivateRoute
              path="/user-profile"
              component={withRouter(UserProfile)}
              roles={pagesAccess.profile}
            />
            <PrivateRoute
              path="/settings"
              component={withRouter(Settings)}
              roles={pagesAccess.settings}
            />
            <PrivateRoute
              path="/participant"
              component={withRouter(Participant)}
              roles={pagesAccess.participant}
            />
            <PrivateRoute
              path="/questions"
              component={withRouter(QuestionnairePage)}
              roles={pagesAccess.questionnaire}
            />
            <PrivateRoute
              path="/roles"
              component={withRouter(RolePage)}
              roles={pagesAccess.rolePage}
            />
            <PrivateRoute
              path="/static-pages"
              component={withRouter(PagesEditor)}
              roles={pagesAccess.pages}
            />
            <PrivateRoute
              path="/competitions"
              component={withRouter(CompetitionsList)}
              roles={pagesAccess.competition}
            />
            <Route path="/create-nomination" component={() => <NominationsList />} />
            <Route path="/sign-up" component={withRouter(SignUp)} />
            <Route path="/invite-public-nomination" component={withRouter(PublicNomination)} />
            <Route path="/login" component={withRouter(Login)} />
            <Route path="/reset-password" component={withRouter(ResetPassword)} />
            <Route path="/userResetPassword" component={withRouter(ApproveResetPassword)} />
            <Route path="/nomination-page/:id" component={withRouter(NominationPage)} />
            <Route path="/reporting/:competitionId" component={withRouter(ReportingBy)} />
            <Route path="/reporting" component={withRouter(Reporting)} />
            <Route path="/email-templates/:id" component={withRouter(EmailTemplates)} />
            <Route
              path="/answers-results/:categoryId/:competitionId"
              component={withRouter(AnswersResultsPage)}
            />
            <Route path="/voting-questions/:id" component={withRouter(VotingTemplates)} />
            <Route path="/public-voting/:competitionId" component={withRouter(PublicVoting)} />
            <Route path="/unsubscribe/:userId" component={withRouter(Unsubscribe)} />
            <Route path="/import-nominations" component={withRouter(ImportNominations)} />
            <Route component={() => <Page404 />} />
          </Switch>
        </Suspense>
      </Content>
    );
  }
}

ContentPages.propTypes = {
  sideBarState: propTypes.bool
};

export default ContentPages;
