export const SUPER_ADMIN = 'SUPER_ADMIN';
export const COMPETITION_OWNER = 'COMPETITION_OWNER';
export const COUNTRY_PARTNER = 'COUNTRY_PARTNER';
export const GLOBAL_JURY_MEMBER = 'GLOBAL_JURY_MEMBER';
export const REGIONAL_JURY_MEMBER = 'REGIONAL_JURY_MEMBER';
export const NATIONAL_JURY_MEMBER = 'NATIONAL_JURY_MEMBER';
export const ADVISOR = 'ADVISOR';
export const AMBASSADOR = 'AMBASSADOR';
export const CONTESTANT = 'CONTESTANT';
export const PUBLIC = 'PUBLIC';
export const NOMINATION = 'NOMINEE';
export const COMPETITION_LEAD = 'COMPETITION_LEAD';
