export const SET_REGION = 'SET_REGION';

export function setRegion(region) {
  return {
    type: SET_REGION,
    region
  };
}

export const SET_ROLE = 'SET_ROLE';

export function setRole(role, roleLabel) {
  return {
    type: SET_ROLE,
    role,
    roleLabel
  };
}

export const DISABLE_ROLE = 'DISABLE_ROLE';

export function disabledRole() {
  return {
    type: DISABLE_ROLE
  };
}
