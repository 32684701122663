import {
  ADVISOR,
  AMBASSADOR,
  COMPETITION_OWNER,
  CONTESTANT,
  COUNTRY_PARTNER,
  GLOBAL_JURY_MEMBER,
  NATIONAL_JURY_MEMBER,
  PUBLIC,
  REGIONAL_JURY_MEMBER,
  SUPER_ADMIN,
  NOMINATION,
  COMPETITION_LEAD
} from './roles';

export const pagesAccess = {
  welcome: [
    COMPETITION_OWNER,
    COMPETITION_LEAD,
    AMBASSADOR,
    NOMINATION,
    GLOBAL_JURY_MEMBER,
    COUNTRY_PARTNER,
    ADVISOR,
    REGIONAL_JURY_MEMBER,
    NATIONAL_JURY_MEMBER
  ],
  dashboard: [
    SUPER_ADMIN,
    COMPETITION_OWNER,
    COMPETITION_LEAD,
    AMBASSADOR,
    NOMINATION,
    COUNTRY_PARTNER,
    ADVISOR,
    REGIONAL_JURY_MEMBER,
    NATIONAL_JURY_MEMBER,
    GLOBAL_JURY_MEMBER
  ],
  region: [SUPER_ADMIN],
  inviteUser: [SUPER_ADMIN, COMPETITION_OWNER, COMPETITION_LEAD],
  competition: [SUPER_ADMIN, COMPETITION_OWNER, COMPETITION_LEAD],
  categories: [SUPER_ADMIN, COMPETITION_OWNER, COMPETITION_LEAD],
  question: [SUPER_ADMIN, COMPETITION_OWNER],
  questionnaire: [SUPER_ADMIN, COMPETITION_OWNER],
  execution: [SUPER_ADMIN, COMPETITION_OWNER, COMPETITION_LEAD],
  profile: [
    SUPER_ADMIN,
    COMPETITION_LEAD,
    COMPETITION_OWNER,
    AMBASSADOR,
    NOMINATION,
    COUNTRY_PARTNER,
    ADVISOR,
    NATIONAL_JURY_MEMBER,
    REGIONAL_JURY_MEMBER,
    GLOBAL_JURY_MEMBER
  ],
  settings: [
    SUPER_ADMIN,
    COMPETITION_LEAD,
    COMPETITION_OWNER,
    AMBASSADOR,
    NOMINATION,
    COUNTRY_PARTNER,
    ADVISOR,
    NATIONAL_JURY_MEMBER,
    REGIONAL_JURY_MEMBER,
    GLOBAL_JURY_MEMBER
  ],
  participant: [SUPER_ADMIN, COMPETITION_OWNER, AMBASSADOR, COMPETITION_LEAD],
  nominates: [SUPER_ADMIN, AMBASSADOR, COUNTRY_PARTNER],
  reporting: [SUPER_ADMIN, COMPETITION_OWNER, COMPETITION_LEAD, COUNTRY_PARTNER],
  pages: [SUPER_ADMIN, COMPETITION_OWNER],
  rolePage: [SUPER_ADMIN, COMPETITION_OWNER]
};

export const permissions = {
  competition: {
    create: [SUPER_ADMIN],
    update: [SUPER_ADMIN, COMPETITION_OWNER],
    delete: [SUPER_ADMIN],
    view: [SUPER_ADMIN, COMPETITION_OWNER, COMPETITION_LEAD, AMBASSADOR]
  },
  category: {
    create: [SUPER_ADMIN],
    update: [SUPER_ADMIN, COMPETITION_OWNER],
    delete: [SUPER_ADMIN],
    view: [SUPER_ADMIN, COMPETITION_OWNER, COMPETITION_LEAD]
  },
  userList: {
    create: [SUPER_ADMIN, COMPETITION_OWNER],
    update: [SUPER_ADMIN, COMPETITION_OWNER],
    delete: [SUPER_ADMIN, COMPETITION_OWNER],
    view: [SUPER_ADMIN, COMPETITION_OWNER, COMPETITION_LEAD],
    viewOnly: [COMPETITION_LEAD]
  },
  executeQuestionForm: {
    execute: [NOMINATION, SUPER_ADMIN, COMPETITION_OWNER, COMPETITION_LEAD]
  }
};

export const inviteRoles = {
  SUPER_ADMIN: [
    COMPETITION_OWNER,
    AMBASSADOR,
    ADVISOR,
    CONTESTANT,
    COUNTRY_PARTNER,
    GLOBAL_JURY_MEMBER,
    NATIONAL_JURY_MEMBER,
    PUBLIC,
    REGIONAL_JURY_MEMBER
  ],
  COMPETITION_OWNER: [
    AMBASSADOR,
    COUNTRY_PARTNER,
    ADVISOR,
    NATIONAL_JURY_MEMBER,
    REGIONAL_JURY_MEMBER
  ]
};
