export const NOMINEE_SORTING = 'NOMINEE_SORTING';
export const CLEAN_ALL_FILTERS = 'CLEAN_ALL_FILTERS';

export function nomineeSorting(sortingData) {
  return {
    type: NOMINEE_SORTING,
    data: sortingData
  };
}

export function cleanAllFiltersAction() {
  return {
    type: CLEAN_ALL_FILTERS
  };
}
