import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { user } from './user';
import { modalEdit } from './modalEdit';
import { nomineeSortData } from './nomineeSort';

export default combineReducers({
  form: formReducer,
  user,
  modalEdit,
  nomineeSortData
});
