import { MODAL_EDIT } from '../actions/modalEdit';

const initialState = {
  editModalData: null
};

export function modalEdit(state = initialState, action) {
  switch (action.type) {
    case MODAL_EDIT:
      return {
        ...state,
        editModalData: action.data
      };

    default:
      return state;
  }
}
