import React, { Component, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { compose } from 'react-apollo';
import { bindActionCreators } from 'redux';
import { updateUserAction } from './actions/updateUserData';

import SideBar from './components/SideBar';
import Header from './components/Header';
import ContentPages from './ContentPages';

import './App.css';

import { NOMINATION } from './constants/roles';

import UpdateUser from './containers/Users/UpdateUser';
import propTypes from 'prop-types';
import lazyWithRetry from './lazyWithRetry';

const Page404 = lazyWithRetry(() => import('./containers/404'));
const ResetPassword = lazyWithRetry(() => import('./containers/auth/ResetPassword'));
const SignUp = lazyWithRetry(() => import('./containers/auth/SignUp'));
const Login = lazyWithRetry(() => import('./containers/auth/Login'));
const PublicNomination = lazyWithRetry(() => import('./containers/PublicNomination'));
const PrivacyPolicy = lazyWithRetry(() => import('./containers/PrivacyPolicy'));
const TermsAndConditions = lazyWithRetry(() => import('./containers/terms-and-conditions'));
const ApproveResetPassword = lazyWithRetry(() => import('./containers/auth/ApproveResetPassword'));
const Unsubscribe = lazyWithRetry(() => import('./containers/Unsubscribe'));
const PublicVoting = lazyWithRetry(() => import('./containers/PublicVoting'));
const FbVoteRemoval = lazyWithRetry(() => import('./containers/PublicVoting/FbVoteRemoval'));

export const Pages = styled.div`
  width: 100%;
  min-width: 100%;
  transition: all 0.3s ease;

  @media (min-width: 992px) {
    width: ${(props) => (props.sideBarState ? 'calc(100% - 240px)' : 'calc(100% - 75px)')};
    min-width: ${(props) => (props.sideBarState ? 'calc(100% - 240px)' : 'calc(100% - 75px)')};
  }

  &.empty {
    width: 100%;
  }
`;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sideBarState: true,
      questionsPass: false,
      errorOccurred: false
    };
  }

  async componentDidMount() {
    const hashLinkRegexp = /#/gi;
    const result = hashLinkRegexp.exec(window.location.href);

    if (result && result.length > 0) {
      window.location.replace(window.location.href.replace('#/', ''));
    }

    if (window.innerWidth <= 991) {
      this.setState({ sideBarState: false });
    }
  }

  toggleSideBar = () => {
    this.setState({ sideBarState: !this.state.sideBarState });
  };

  renderPrivatePart() {
    const { sideBarState } = this.state;
    const { user } = this.props;

    return (
      <div className="App">
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <SideBar sideBarState={sideBarState} />
        <Pages sideBarState={sideBarState}>
          <Header toggleSideBar={this.toggleSideBar} sideBarState={sideBarState} />
          {user && user.role !== NOMINATION && <UpdateUser />}
          <ContentPages sideBarState={sideBarState} />
        </Pages>
      </div>
    );
  }

  renderPublicPart() {
    return (
      <Suspense fallback={null}>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <Switch>
          <Route exact path="/" component={withRouter(Login)} />
          <Route path="/reset-password" component={withRouter(ResetPassword)} />
          <Route path="/userResetPassword" component={withRouter(ApproveResetPassword)} />
          <Route path="/sign-up" component={withRouter(SignUp)} />
          <Route path="/privacy-policy" component={withRouter(PrivacyPolicy)} />
          <Route path="/terms-and-conditions" component={withRouter(TermsAndConditions)} />
          <Route path="/invite-public-nomination" component={withRouter(PublicNomination)} />
          <Route path="/login" component={withRouter(Login)} />
          <Route path="/public-voting/:competitionId" component={withRouter(PublicVoting)} />
          <Route path="/fb-vote-remove" component={withRouter(FbVoteRemoval)} />
          <Route path="/unsubscribe/:userId" component={withRouter(Unsubscribe)} />
          <Route component={() => <Page404 />} />
        </Switch>
      </Suspense>
    );
  }

  render() {
    const { user } = this.props;
    const isLogin = user && user.id;

    return (
      <Router basename="/">{isLogin ? this.renderPrivatePart() : this.renderPublicPart()}</Router>
    );
  }
}

App.propTypes = {
  user: propTypes.object
};

export function mapStateToProps({ user }) {
  return {
    user
  };
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserAction
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(App);
