export const LOGIN_USER = 'LOGIN_USER';
export const LOG_OUT_USER = 'LOG_OUT_USER';

export function loginUserAction(data) {
  const firstRegion = data.roles && data.roles.length > 0 ? data.roles[0].region : null;
  const roleLabel =
    data.availableRoles && data.availableRoles.length ? data.availableRoles[0].roleLabel : '';
  const newData = { ...data, region: firstRegion, roleLabel };

  return {
    type: LOGIN_USER,
    data: newData
  };
}

export function logOutUser() {
  return {
    type: LOG_OUT_USER
  };
}
