import { UPDATE_USER_PROFILE } from '../actions/updateUser';
import { LOGIN_USER, LOG_OUT_USER } from '../actions/loginUser';
import { SET_REGION, SET_ROLE, DISABLE_ROLE } from '../actions/setRegion';
import { UPDATE_USER } from '../actions/updateUserData';

const initialState = {
  id: null,
  email: null,
  password: null,
  role: null,
  roleLabel: null,
  isEnabledRole: true,
  roles: [],
  region: null,
  authorization: null,
  availableRoles: null,
  emailSubscription: true
};

export function user(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...initialState,
        ...action.data
      };
    case UPDATE_USER_PROFILE:
      return {
        ...state,
        ...action.data
      };
    case SET_REGION:
      return {
        ...state,
        region: action.region,
        isEnabledRole: false
      };
    case SET_ROLE:
      return {
        ...state,
        role: action.role,
        roleLabel: action.roleLabel,
        isEnabledRole: true
      };
    case DISABLE_ROLE:
      return {
        ...state,
        isEnabledRole: false
      };
    case UPDATE_USER:
      return {
        ...state,
        ...action.data
      };
    case LOG_OUT_USER:
      return initialState;

    default:
      return state;
  }
}
