/* eslint import/no-extraneous-dependencies: "off", react/jsx-props-no-spreading: "off" */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './reducer';
import persistState from 'redux-localstorage';
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import * as serviceWorker from './serviceWorker';

import { onError } from 'apollo-link-error';

import { logOutUser } from './actions/loginUser';

import './index.css';
import App from './App';

const enhancer = compose(
  persistState(['user']),
  composeWithDevTools(applyMiddleware(thunkMiddleware))
);

const options = {
  position: positions.TOP_RIGHT,
  timeout: 5000,
  offset: '30px',
  transition: transitions.FADE
};

const store = createStore(reducer, enhancer);

const logOut = () => {
  store.dispatch(logOutUser());
};

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER_URL
});

const authLink = setContext((_, { headers }) => {
  const getlocalStorage = JSON.parse(localStorage.getItem('redux'));

  if (getlocalStorage) {
    const { authorization: token, role, isEnabledRole } = getlocalStorage && getlocalStorage.user;
    const region_id = (getlocalStorage.user.region && getlocalStorage.user.region.id) || '';

    const headersObject = {
      ...headers,
      authorization: token || null,
      'x-region-id': region_id
    };
    if (isEnabledRole) {
      headersObject['x-role'] = role;
    }

    return {
      headers: headersObject
    };
  } else {
    return {
      headers: {}
    };
  }
});
const logoutLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    // eslint-disable-next-line
    graphQLErrors.map(({ message, locations, path }) => {
      if (message === 'NotAuthorized') logOut();
    });
});

const client = new ApolloClient({
  link: ApolloLink.from([authLink, logoutLink, uploadLink]),
  cache: new InMemoryCache({ addTypename: false })
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <AlertProvider template={AlertTemplate} {...options}>
          <App />
        </AlertProvider>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
