export const UPDATE_USER = 'UPDATE_USER_INFO';

export function updateUserAction(data) {
  const firstRegion = data.roles && data.roles.length > 0 ? data.roles[0].region : null;
  const newData = { ...data, region: firstRegion };

  return {
    type: UPDATE_USER,
    data: newData
  };
}

export function updateUser(data, region) {
  if (region && region.id) {
    return {
      type: UPDATE_USER,
      data: { ...data, region: region }
    };
  }

  return {
    type: UPDATE_USER,
    data
  };
}
