import gql from 'graphql-tag';

export const GET_ROLES = gql`
  query getRoles {
    getRoles
  }
`;

export const GET_REGIONS = gql`
  query getRegions {
    getRegions {
      items {
        id
        name
        isGlobalRegion
      }
    }
  }
`;

export const GET_COMPETITIONS = gql`
  query getCompetitions {
    getCompetitions {
      id
      name
      description
      roleList {
        name
        label
      }
      userRoleList {
        name
        label
      }
      countriesList {
        id
        name
        iso2
        iso3
        flag
      }

      categories {
        id
        name
        enabled
      }
      categoriesList {
        id
        name
      }
      rounds {
        id
        name
        date {
          startDate
          endDate
        }
        roles
      }
    }
  }
`;

export const GET_COMPETITION_ROLES = gql`
  query getCompetitionRoles($id: ID) {
    getCompetitionRoles(id: $id) {
      id
      label
      name
    }
  }
`;
