import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

function PrivateRoute({ user, roles, emptyPage: EmptyPage, component: Component, ...rest }) {
  const isLogin = user && user.id;
  const isAvailable = roles.includes(user.role);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAvailable && isLogin) {
          return <Component {...props} />;
        } else {
          if (EmptyPage) return <EmptyPage {...props} />;
          return <Redirect to={{ pathname: '/' }} />;
        }
      }}
    />
  );
}

const mapStateToProps = ({ user }) => ({ user });

export default connect(mapStateToProps)(PrivateRoute);
