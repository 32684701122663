import styled from 'styled-components';

export const Button = styled.button`
  display: inline-block;
  min-width: 100px;
  border: none;
  outline: none;
  padding: 10px 15px;
  background: none;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.2s;

  &:disabled {
    border-color: #aeaeae !important;
    background-color: #fafafa !important;
    color: #aeaeae !important;
    cursor: not-allowed;
  }
`;

export const AddButton = styled(Button)`
  border: 1px solid #177dff;
  color: #177dff;
  background-color: #fff;

  &:hover {
    color: #fff;
    background-color: #177dff;
  }
`;

export const CancelButton = styled(Button)`
  border: 1px solid #fbb637;
  color: #fbb637;
  background-color: #fff;

  &:hover {
    color: #fff;
    background-color: #fbb637;
  }
`;

export const AlertBtn = styled(Button)`
  border: 1px solid #fb4704;
  color: #fb4704;
  background-color: #fff;

  &:hover {
    color: #fff;
    background-color: #fb4704;
  }
`;

export const DownloadButton = styled(Button)`
  border: 1px solid #57ce57;
  color: #57ce57;
  background-color: #fff;

  &:hover {
    color: #fff;
    background-color: #57ce57;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.onlyBaseLine {
    position: relative;

    &:after {
      content: 'You could approve only from the baseline category';
      position: absolute;
      top: calc(100% + 5px);
      left: 50%;
      width: 100%;
      padding: 0 10px;
      font-size: 12px;
      color: red;
      text-transform: none;
      white-space: normal;
      transform: translateX(-50%);
    }
  }

  @media (max-width: 767px) {
    width: 100%;
    margin: 0 !important;
    white-space: normal;
  }
`;

export const EditButton = styled(Button)`
  border: 1px solid #177dff;
  color: #177dff;
  background-color: #fff;
  height: 40px;

  &:hover {
    color: #fff;
    background-color: #177dff;
  }
`;

export const DeleteButton = styled(Button)`
  border: 1px solid red;
  color: red;
  background-color: #fff;

  &:hover {
    color: #fff;
    background-color: red;
  }
`;
