export const getNewDate = (date) => {
  return new Date(date);
};

export const renameObjectForSelect = (obj, defaultLabel = 'Select an option') => {
  if (obj) {
    return {
      value: obj.id,
      label: obj.name
    };
  } else {
    return {
      value: null,
      label: defaultLabel
    };
  }
};

export const renameObjectForSubmit = (obj) => {
  if (obj) {
    return {
      id: obj.value,
      name: obj.label
    };
  } else {
    return {
      id: '',
      name: ''
    };
  }
};

export function isEmpty(obj) {
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
  }
  return true;
}
