import { NOMINEE_SORTING, CLEAN_ALL_FILTERS } from '../actions/nomineeSorting';

const initialState = {
  country: [],
  status: [],
  category: [],
  ambassador: [],
  textInput: ''
};

export function nomineeSortData(state = initialState, action) {
  switch (action.type) {
    case NOMINEE_SORTING:
      return {
        ...state,
        ...action.data
      };
    case CLEAN_ALL_FILTERS:
      return {
        state
      };

    default:
      return state;
  }
}
