import React, { Component } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
  FaHome,
  FaClipboardList,
  FaUsers,
  FaQuestionCircle,
  FaFlag,
  FaClipboard,
  FaFile
} from 'react-icons/fa';
import { pagesAccess } from '../constants/roleAccess';

const LogoImgSmall = require(`../images/${process.env.REACT_APP_SMALL_LOGO_NAME}`);
const LogoImg = require(`../images/${process.env.REACT_APP_BIG_LOGO_NAME}`);

const SideBarStyle = styled.div`
  height: 100vh;
  width: ${(props) => (props.sideBarState ? '240px' : '0')};
  min-width: ${(props) => (props.sideBarState ? '240px' : '0')};
  overflow: auto;
  background-color: #fff;
  box-shadow: 2px 0 20px rgba(69, 65, 78, 0.07);
  transition: all 0.3s ease;

  @media (min-width: 992px) {
    width: ${(props) => (props.sideBarState ? '240px' : '75px')};
    min-width: ${(props) => (props.sideBarState ? '240px' : '75px')};
  }
`;
const TopPart = styled.div`
  padding: 0 15px;
`;
const BottomPart = styled.div`
  overflow: auto;
`;
const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f1f1f1;
  border-bottom: 1px solid #f1f1f1;
  padding: 6px 0 5px;

  a {
    width: ${(props) => (props.sideBarState ? '130px' : '45px')};
  }

  img {
    object-fit: contain;
  }
`;
const Navigation = styled.nav`
  li {
    & + li {
      margin-top: 3px;
    }

    a {
      position: relative;
      display: flex;
      align-items: center;
      padding: 8px 25px;
      text-decoration: none;
      color: #575962;
      font-size: 14px;
      line-height: 1.2;
      transition: all .1s ease;

      span {
        padding: 5px 0;
      }

      svg {
        fill: #a1a2a6;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 3px;
        height: 100%;
        background: #1d7af3;
        opacity: 0;
        z-index: 1;
      }

      &.active,
      &:hover {
        &::before {
          opacity: 1;
        }

        &.active {
          font-weight: 600;
          background: rgba(0, 0, 0, .05);

          svg {
            fill: #4d7cfe;
          }
        }

        &:hover {
          background: rgba(0, 0, 0, .05);

          &::before {
            opacity: .7;
          }
        }
      }
    }
`;
const IconBox = styled.span`
  margin-right: 15px;
`;
const LinkName = styled.span`
  width: ${(props) => (props.sideBarState ? 'auto' : '0px')};
  white-space: nowrap;
  overflow: hidden;
`;
const SideBarTitle = styled.div`
  height: ${(props) => (props.sideBarState ? 'auto' : '0px')};
  border: ${(props) => (props.sideBarState ? 'none' : '1px solid #989696')};
  padding: ${(props) => (props.sideBarState ? '8px 25px' : '0px')};
  background-color: #989696;
  color: #fff;
  overflow: hidden;
`;

class SideBar extends Component {
  generalList = [
    {
      label: 'Home',
      link: '/welcome',
      icon: <FaHome size="1.6em" />,
      roles: pagesAccess.welcome,
      exact: true
    },
    {
      label: 'Dashboard',
      link: '/',
      icon: <FaFile size="1.6em" />,
      roles: pagesAccess.dashboard,
      exact: true,
      fbLogin: true,
    },
    {
      label: 'Regions',
      link: '/regions-list',
      icon: <FaFlag size="1.6em" />,
      roles: pagesAccess.region
    },
    {
      label: 'Questions',
      link: '/questions',
      icon: <FaQuestionCircle size="1.6em" />,
      roles: pagesAccess.questionnaire
    },
    {
      label: 'Categories',
      link: '/categories',
      icon: <FaClipboardList size="1.6em" />,
      roles: pagesAccess.categories
    }
  ];

  regionalList = [
    {
      label: 'Competitions',
      link: '/competitions',
      icon: <FaClipboard size="1.6em" />,
      roles: pagesAccess.competition
    },
    {
      label: 'Users',
      link: '/users',
      icon: <FaUsers size="1.6em" />,
      roles: pagesAccess.inviteUser
    },
    {
      label: 'Reporting',
      link: '/reporting',
      icon: <FaClipboard size="1.6em" />,
      roles: pagesAccess.reporting
    }
  ];

  renderList(collection) {
    const { user, sideBarState, fbLogin} = this.props;

    return collection.map((item) => {
      if (!(item.roles.includes(user.role) || (item.fbLogin && fbLogin))) return null;
      return (
        <li key={item.link}>
          <NavLink to={item.link} activeClassName="active" exact={item.exact} title={item.label}>
            <IconBox>{item.icon}</IconBox>
            <LinkName sideBarState={sideBarState}>{item.label}</LinkName>
          </NavLink>
        </li>
      );
    });
  }

  render() {
    const { sideBarState } = this.props;
    const regionalListCount = this.renderList(this.regionalList).filter(
      (item) => item !== null && item
    );

    return (
      <SideBarStyle id="side-bar" sideBarState={sideBarState}>
        <TopPart>
          <LogoBox sideBarState={sideBarState}>
            <Link to="/">
              <img src={sideBarState ? LogoImg : LogoImgSmall} alt="Logo" />
            </Link>
          </LogoBox>
        </TopPart>
        <BottomPart>
          <Navigation>
            <ul>
              <SideBarTitle sideBarState={sideBarState}>General</SideBarTitle>
              {this.renderList(this.generalList)}
              {regionalListCount.length > 0 && (
                <SideBarTitle sideBarState={sideBarState}>Region-specific</SideBarTitle>
              )}
              {this.renderList(this.regionalList)}
            </ul>
          </Navigation>
        </BottomPart>
      </SideBarStyle>
    );
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default withRouter(connect(mapStateToProps)(SideBar));
