import { Component } from 'react';
import { compose, graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import gql from 'graphql-tag';
import { bindActionCreators } from 'redux';
import { updateUserAction, updateUser } from '../../actions/updateUserData';
import { logOutUser } from '../../actions/loginUser';

class UpdateUser extends Component {
  state = {
    ready: false
  };

  async componentDidMount() {
    const { getMyProfile, updateUser, history, logOutUser, user } = this.props;
    try {
      if (getMyProfile && getMyProfile.refetch) {
        const profile = await getMyProfile.refetch();

        updateUser(profile.data.getMyProfile, user.region);
        if (profile.data.getMyProfile) {
          setTimeout(() => {
            this.setState({ ready: true });
          }, 200);
        }
      }
    } catch (error) {
      logOutUser();
      history.replace('/');
    }
  }
  async componentDidUpdate(prevProps) {
    const { user, getMyProfile, updateUser } = this.props;
    const { ready } = this.state;

    if (prevProps.user.region && user.region) {
      if (prevProps.user.region.id !== user.region.id && ready) {
        const profile = await getMyProfile.refetch();
        updateUser(profile.data.getMyProfile);
      }
    } else if (prevProps.user.role !== user.role && ready) {
      const profile = await getMyProfile.refetch();
      updateUser(profile.data.getMyProfile);
    }
  }
  render() {
    return null;
  }
}

export const GET_MY_PROFILE = gql`
  query getMyProfile {
    getMyProfile {
      name
      email
      role
      phone
      emailSubscription
      availableRoles {
        role
        roleLabel
      }
      roles {
        role
        region {
          id
          name
          isGlobalRegion
        }
      }
      socialLinks {
        facebook
        linkedin
        twitter
      }
    }
  }
`;

const mapStateToProps = ({ user }) => ({ user });

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUserAction,
      updateUser,
      logOutUser
    },
    dispatch
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  graphql(GET_MY_PROFILE, {
    name: 'getMyProfile',
    options: {
      fetchPolicy: 'network-only'
    }
  })
)(UpdateUser);
